import React, { Component } from 'react';
import Header from "../components/Header";
import Footer from "../components/Footer";
import {Helmet} from "react-helmet";
import ReactPlayer from "react-player/lazy";
import {renderRichText} from "gatsby-source-contentful/rich-text";
import {graphql} from "gatsby";
import {BLOCKS, INLINES, MARKS} from "@contentful/rich-text-types";
import {GatsbyImage, getImage} from "gatsby-plugin-image";

class PodcastPage extends Component {
    render() {
        const options = {
            renderMark: {
                [MARKS.BOLD]: (text) => <b>{text}</b>
            },
            renderNode: {
                [INLINES.HYPERLINK]: (node, children) => {
                    const {uri} = node.data
                    return (
                        <a href={uri} style={{color: ""}} target="_blank" rel="noopener, noreferrer">
                            {children}
                        </a>
                    )
                },
                [BLOCKS.HEADING_2]: (node, children) => {
                    return <h2>{children}</h2>
                },

                [BLOCKS.EMBEDDED_ASSET]: (node) => {
                    const {gatsbyImageData, description} = node.data.target
                    return (
                        <GatsbyImage
                            image={getImage(gatsbyImageData)}
                            alt={description}
                        />
                    )
                },
            }
        }
        return(
            <>
                <Helmet>
                    <html lang="pt-BR" amp />
                    <meta charSet="UTF-8" />
                    <title>{this.props.data.podcast.titulo} | Podcast Cia. Ibérica</title>
                    <meta name="description" content={this.props.data.podcast.titulo} />
                    <meta name="robots" content="index,follow,max-snippet: -1,max-image-preview:large" />
                    <link rel="canonical" href={`https://www.ciaiberica.com.br/videos/${this.props.data.podcast.urlPodcast}/`} />
                    <meta property="og:locale" content="pt-BR" />
                    <meta property="og:type" content="article" />
                    <meta property="og:title" content={this.props.data.podcast.titulo + "| Podcast Cia. Ibérica"} />
                    <meta property="og:description" content={this.props.data.podcast.titulo} />
                    <meta property="og:url" content={`https://www.ciaiberica.com.br/video/${this.props.data.podcast.urlPodcast}/`} />
                    <meta property="og:sitename" content="Cia. Ibérica" />
                    <meta property="article:publisher" content="https://facebook.com/ibericacondutoreseletricosltda" />
                    <meta property="article:published_time" content={this.props.data.podcastData.createdAt} />
                    <meta property="article:modified_time" content={this.props.data.podcastData.updatedAt} />
                </Helmet>
                <Header />
                <ReactPlayer url={this.props.data.podcast.urlYoutube} width={"100%"} height={"500px"} />
                <div className="container mt-5 mb-5">
                    <h2>{this.props.data.podcast.titulo}</h2>
                    <p>Publicado por Time Ibérica em {this.props.data.podcast.createdAt}</p>
                    {renderRichText(this.props.data.podcast.descricao, options)}
                </div>
                <Footer />
            </>
        )
    }
}

export const query = graphql`
    query($urlPodcast:String!) {
        podcast: contentfulPodcasts(urlPodcast:{eq:$urlPodcast}) {
            id
            urlPodcast
            urlYoutube
            titulo
            createdAt(formatString: "DD/MM/yyyy, HH:mm")
            updatedAt(formatString: "DD/MM/yyyy, HH:mm")
            descricao {
                raw
            }
        }
        podcastData: contentfulPodcasts(urlPodcast:{eq:$urlPodcast}) {
            createdAt
            updatedAt
        }

    }
`

export default PodcastPage;